<template>
    <v-container row justify-center>
        <v-dialog max-width="1200px" persistent v-model="isShowSettledTransactions">
            <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" />
            <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage" v-on:close-dialog="closeDialog" />
            <v-card>
                <v-card-title class="headline">
                    Settled Transactions: {{settledTransactions.title}}
                </v-card-title>
                <v-card>
                    <v-data-table 
                        :hide-default-header="true" 
                        :headers="headers" 
                        :items="displayReports"
                        :loading="loading" 
                        :page="page" 
                        :pageCount="numberOfPages" 
                        :options.sync="options"
                        :server-items-length="totalReports"
                        :footer-props="{'items-per-page-options': [10, 20, 50]}">
                        <template v-slot:header="{ props: { headers } }">
                            <thead>
                                <tr :colspan="headers.length">
                                    <th v-for="header in headers" :key="header.text">
                                        <span class="d-flex justify-center">{{header.text}}</span>
                                    </th>
                                </tr>
                            </thead>
                        </template>
                        <template v-slot:body="{ items }">
                            <tbody>
                                <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                                    <td> <span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.primaryAccountNumber }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.retrievalReferenceNumber }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.amount }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.authorizationCode }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.mti }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.responseCode }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.messageStatus }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.terminalId }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ item.systemTraceAuditNumber }} </span></td>
                                    <td> <span class="d-flex justify-center"> {{ parseMti(item) }} </span></td>
                                    <td>
                                        <span class="d-flex justify-center">
                                            <v-btn fab dark x-small color="#00a8e8" class="mr-2" @click.stop="viewPayload(item)">
                                                <v-icon dark medium>mdi-magnify-plus</v-icon>
                                            </v-btn>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                        <template v-slot:no-results>
                            <v-alert :value="true" color="error" icon="warning">
                                Found no results.
                            </v-alert>
                        </template>
                    </v-data-table>
                </v-card>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close" data-cy="close-button">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
    </template>
    
    <script>
    import {
        mapGetters
    } from 'vuex';
    import API from '../../api/API';
    import DetailsForm from '@/views/components/DetailsForm.vue'
    import Loading from '@/views/components/Loading.vue'
    export default {
        props: {
            isShow: Boolean,
            loadingDialog: Boolean,
            settledTransactions: Object
        },
        components: {
            DetailsForm,
            Loading
        },
        computed: {
        ...mapGetters([
                'attributes',
                'authorities'
            ]),
        },
        watch: {
            isShow: {
                handler(value) {
                    this.isShowSettledTransactions= value
                    this.queryData()
                }
            },
            options: {
                handler() {
                    this.queryData()
                },
            },
            deep: true,
        },
        mounted() {
        },
        data() {
            return {
                headers: [
                    {
                        text: 'Date Created',
                        value: 'dateCreated'
                    },
                    {
                        text: 'Primary Account No',
                        value: 'primaryAccountNumber'
                    },
                    {
                        text: 'Retrieval Reference No',
                        value: 'retrievalReferenceNumber'
                    },
                    {
                        text: 'Amount',
                        value: 'amount'
                    },
                    {
                        text: 'Authorization Code',
                        value: 'authorizationCode'
                    },
                    {
                        text: 'MTI',
                        value: 'mti'
                    },
                    {
                        text: 'Response Code',
                        value: 'responseCode'
                    },
                    {
                        text: 'Message Status',
                        value: 'messageStatus'
                    },
                    {
                        text: 'Terminal ID',
                        value: 'terminalId'
                    },
                    {
                        text: 'System Trace Audit No',
                        value: 'systemTraceAuditNumber'
                    },
                    {
                        text: 'Transaction Type',
                        value: 'transactionType'
                    },
                    {
                        text: 'Action',
                        value: ''
                    }
                ],
                loading: true,
                loadingMessage: "",
                loadingFinished: false,
                isShowSettledTransactions: this.isShow ?? false,
                page: 1,
                totalReports: 0,
                numberOfPages: 0,
                options: {
                    rowsPerPage: 10
                },
                displayReports: [],
                remainderReports: [],
                isHtml: false,
                details: {
                    title: '',
                    item: {}
                },
                isShowDetails: false,
                }
        },
        methods: {
            viewPayload(item) {
                this.details.title = item.id
                this.details.item = item
                this.isShowDetails = true
            },
            parseMti(value) {
                switch(value.mti) {
                    case "0200":
                        return "Debit Sale"
                    case "0100":
                        return "Sale"
                    default:
                        return value.transactionType    
                }    
            },
            viewPayload(item) {
                this.details.title = item.id
                this.details.item = item
                this.isShowDetails = true
            },
            async queryData(isSearch = false) {
                this.loading = true
                if (isSearch) {
                    this.options.page = 1
                }
                const {
                    page,
                    itemsPerPage
                } = this.options;
                let pageNumber = page - 1;
                try {
                    const limit = itemsPerPage
                    var settledTransactionList = undefined
                    const parameters = {
                        id: this.settledTransactions.item.id,
                    }
                    settledTransactionList = await API.getSettledTransactionList(pageNumber, limit, parameters)
                    if (!settledTransactionList || settledTransactionList.error) {
                        //error getting data
                        console.log(`${settledTransactionList.error}`)
                    } else {
                        var filteredList = JSON.parse(settledTransactionList.data)
                        console.log("Filtered List", filteredList)
                        if (page > 1 && this.remainderReports.length > 0) {
                            filteredList = this.remainderReports.concat(filteredList)
                        }
                        this.totalReports = settledTransactionList.totalReports
                        const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                        this.displayReports = filteredList.slice(0, end)
                        this.remainderReports = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                        this.numberOfPages = settledTransactionList.pageCount
                    }
                } catch (e) {
                    console.log(e)
                }
                this.loading = false
            },
            close() {
                this.isShowSettledTransactions = false
                this.$emit('close-dialog', 'settledTransactionList')
            },
            closeDialog(type) {
                if (type === 'loading') {
                    this.loadingDialog = false
                    this.queryData;
                } else if (type === 'details') {
                    this.details.title = ''
                    this.details.item = {}
                    this.isShowDetails = false
                } else {
                    this.loadingDialog = false
                }
            },
        }
    }
    </script>
    