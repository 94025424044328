<template>
    <v-app id="inspire">
        <div class="px-4">
            <settled-transactions-form :is-show="isShowSettledTransactions" :settledTransactions="settledTransactions" v-on:close-dialog="closeDialog" />
            <v-container class="container--fluid grid-list-md text-left" :style="{ fontSize: '15px',  color: '#465a75' }">
                <v-icon :style="{ color: '#465a75',paddingBottom: '11px', marginRight: '5px' }" left-bottom x-large>mdi-deskphone</v-icon> Terminal Settlement
            </v-container>
            <v-card outlined :style="{ border: '1px solid #dedede', marginBottom: '20px', marginLeft: '10px', marginRight: '10px'}" shaped tile class="pa-1">
                <div class="d-flex flex-no-wrap justify-space-between">
                    <v-card-title>
                        <v-container grid-list-md text-xs-center>
                            <v-layout row wrap>
                                <v-flex>
                                    <div class="d-flex">
                                        <span class="mt-4 mr-2">Search By:</span>
                                        <v-text-field class="ma-2" label="Terminal ID" v-model="query.terminalId" single-line outlined @change="search($event, 'terminalId')" data-cy="module-terminal-id"></v-text-field>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-card-title>
                </div>    
                <div class="d-flex flex-no-wrap justify-space-between">    
                    <v-card-title>   
                        <v-container>
                            <v-layout row wrap align-center>
                                <v-row no-gutters>
                                    <v-cols cols="12" md="3" lg :style="{ marginTop: '20px', marginRight: '20px' }">
                                        <v-text> Date Created From: </v-text>
                                    </v-cols>
                                    <v-cols cols="12" md="3" lg>
                                        <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-from">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field class="ma-2" single-line outlined label="Date Created From" append-icon="mdi-calendar" readonly :value="fromDateDisp" v-on="on" data-cy="date-menu-from"></v-text-field>
                                            </template>
                                            <v-date-picker locale="en-in" v-model="query.dateFrom" no-title @input="fromDateMenu = false" :min="minDate" :max="fromMax" data-cy="date-menu-from"></v-date-picker>
                                        </v-menu>
                                    </v-cols>
                                    <v-cols cols="12" md="3" lg :style="{ marginTop: '20px', marginRight: '20px' }">
                                        <v-text> Date Created To: </v-text> 
                                    </v-cols>
                                    <v-cols cols="12" md="3" lg >
                                        <v-menu ref="menu" v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px" data-cy="date-menu-to">
                                            <template v-slot:activator="{ on }">
                                                <v-text-field class="ma-2" single-line outlined label="Date Created To" append-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on" data-cy="date-menu-to"></v-text-field>
                                            </template>
                                            <v-date-picker locale="en-in" v-model="query.dateTo" no-title @input="toDateMenu = false" :max="maxDate" :min="toMin" data-cy="date-menu-to">
                                                <v-btn text color="primary" @click="clearToDate()" data-cy="button-clear">Clear
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-cols>
                                    <v-cols cols="12" md="3" lg :style="{ marginTop: '2px' }">
                                        <v-btn :block="$vuetify.breakpoint.mdAndDown" class="ma-2" x-large color="#1d6499" :style="{ color: 'white' }" @click="queryData(true)" data-cy="button-search">
                                            <v-icon large :style="{ color: '#8eb0ca' }" left> mdi-magnify</v-icon> Search
                                        </v-btn>
                                    </v-cols>
                                    <v-cols cols="12" md="3" lg :style="{ marginTop: '2px' }">
                                        <v-btn class="ma-2" x-large color="#1d6499" :style="{ color: 'white' }" @click="resetInputs()" data-cy="button-reset">
                                            <v-icon large :style="{ color: '#8eb0ca' }" left> mdi-history </v-icon> Reset
                                        </v-btn>
                                    </v-cols>
                                </v-row>
                            </v-layout>
                        </v-container>
                    </v-card-title>
                </div>
            </v-card>
            
            <v-data-table :hide-default-header="true" :headers="headers" :items="displayTransactions"
                      :loading="loading" :page="page" :pageCount="numberOfPages" :options.sync="options"
                      :server-items-length="totalTransactions" class="mb-14"
                      :style="{ border: '1px solid #dedede', marginBottom: '20px', marginLeft: '10px', marginRight: '10px'}"
                      :footer-props="{'items-per-page-options': [10, 20, 50]}">
                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr :colspan="headers.length">
                            <th v-for="header in headers" :key="header.text" :style="{ backgroundColor: '#dfedf8', fontWeight: 'bold', fontSize: '12px', color: 'black'}">
                                <span class="d-flex justify-center">{{header.text}}</span>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr :colspan="headers.length" v-for="item in items" :key="item.id">
                            <td> <span class="d-flex justify-center"> {{ item.id }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.terminalId }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.dateSettled }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.totalCreditAmount }} </span></td>
                            <td> <span class="d-flex justify-center"> {{ item.totalVoidAmount }} </span></td>
                            <td>
                                <span class="d-flex justify-center">
                                    <v-btn fab dark x-small color="#00a8e8" class="mr-2" @click.stop="viewTransaction(item)">
                                        <v-icon dark medium>mdi-magnify-plus</v-icon>
                                    </v-btn>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </template>
                <template v-slot:no-results>
                    <v-alert :value="true" color="error" icon="warning">
                        Found no results.
                    </v-alert>
                </template>
            </v-data-table>
        </div>
    </v-app>
    </template>
    
    <script>
    import API from '@/api/API.js'
    import moment from 'moment';
    import DetailsForm from '@/views/components/DetailsForm.vue'
    import SettledTransactionsForm from '@/views/components/SettledTransactionsForm.vue'
    import {
        mapGetters
    } from 'vuex';
    
    export default {
        name: 'Transactions',
         components: {
            DetailsForm,
            SettledTransactionsForm
        },
        data() {
            return {
                headers: [],
                displayTransactions: [],
                remainderTransactions: [],
                details: {
                    title: '',
                    item: {}
                },
                settledTransactions: {
                    title: '',
                    item: {}
                },
                isShowDetails: false,
                isShowSettledTransactions: false,
                loading: true,
                selectedUser: {},
                page: 1,
                totalTransactions: 0,
                numberOfPages: 0,
                options: {
                    rowsPerPage: 10
                },
                query: {
                    terminalId: '',
                    dateFrom: '',
                    dateTo: ''
                },
                fromDateMenu: false,
                fromDateVal: "",
                toDateMenu: false,
                toDateVal: "",
                minDate: "1997-01-01",
                maxDate: "",
                fromMax: "",
                toMin: ""
            }
        },
        watch: {
            options: {
                handler() {
                    console.log('1')
                    this.queryData()
                },
            },
            deep: true,
        },
        computed: {
            //get role attributes
            ...mapGetters([
                'attributes',
                'user',
                'authorities'
            ]),
            fromDateDisp() {
                this.toMin = moment().format(this.query.dateFrom)
                return this.query.dateFrom;
            },
            toDateDisp() {
                this.fromMax = moment().format(this.query.dateTo)
                return this.query.dateTo;
            }
        },
        async mounted() {
            this.setHeaders()
            this.setDefaultDateRange()
            //this.queryData()
        },
        methods: {
            parseMti(value) {
                switch(value.mti) {
                    case "0200":
                         return "Debit Sale"
                    case "0100":
                         return "Sale"
                    default:
                        return value.transactionType    
                }    
            },
            hasPermission(authority) {
              if(this.authorities.some(x => x.name === authority)){
                return true
              }
              return false
            },
            async queryData(isSearch = false) {
                this.loading = true
                if (isSearch) {
                    this.options.page = 1
                }
                const {
                    page,
                    itemsPerPage
                } = this.options;
                let pageNumber = page - 1;
                try {
                    const limit = itemsPerPage
                    const transactionListResponse = await API.searchTerminalSettlementById(pageNumber, limit, this.query)
                    console.log(JSON.stringify(transactionListResponse))
                    if (!transactionListResponse || transactionListResponse.error) {
                        //error getting data
                        console.log(`${transactionListResponse.error}`)
                    } else {
                        var filteredList = JSON.parse(transactionListResponse.settlements)
                        if (page > 1 && this.remainderTransactions.length > 0) {
                            filteredList = this.remainderTransactions.concat(filteredList)
                        }
                        this.totalTransactions = transactionListResponse.totalItems
                        const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                        this.displayTransactions = filteredList.slice(0, end)
                        this.remainderTransactions = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                        this.numberOfPages = transactionListResponse.pageCount
                    }
                    this.loading = false
                } catch (e) {
                    console.log(e)
                    this.loading = false
                }
            },
            setHeaders() {
                var headers = [
                    {
                        text: 'ID',
                        value: 'id'
                    },
                    {
                        text: 'Terminal ID',
                        value: 'terminalId'
                    },
                    {
                        text: 'Date Settled',
                        value: 'dateSettled'
                    },
                    {
                        text: 'Total Credit Amount',
                        value: 'totalCreditAmount'
                    },
                    {
                        text: 'Total Void Amount',
                        value: 'totalVoidAmount'
                    },
                    {
                        text: 'Action',
                        value: ''
                    }
                ]
                this.headers = headers
            },
            viewTransaction(transaction) {
                console.log(transaction)
                this.settledTransactions.title = transaction.id
                this.settledTransactions.item = transaction
                this.isShowSettledTransactions = true
            },
            closeDialog(type) {
                console.log('type:', type);
                this.settledTransactions.title = ''
                this.settledTransactions.item = {}
                this.isShowSettledTransactions = false
            },
            search(event, type) {
                this.query[type] = event
            },
            getToday() {
                const today = moment().format("yyyy-MM-DD")
                return today
            },
            setDefaultDateRange() {
                this.query.dateFrom = this.getToday();
                this.query.dateTo = ''
                this.maxDate = moment().add(1, "days").format("yyyy-MM-DD")
                this.fromMax = moment().add(1, "days").format("yyyy-MM-DD")
                this.toMin = moment().format(this.query.dateFrom)
            },
            clearToDate() {
                this.query.dateTo = ''
                this.toDateMenu = false
            },
            resetInputs() {
                this.query.terminalId = ''
                this.displayTransactions = [];
                this.details = [];
            }
            
        }
    };
    </script>
    
    <style scoped>
    .v-data-table {
        max-width: 100%
    }
    </style>
    